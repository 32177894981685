import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { isEmpty } from "lodash"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { Content } from "page_components/account"

import { isUserLoggedIn } from "utils/functions"

const Account = () => {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()
    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }
    !auth && navigate("/logowanie/")
  }, [loggedIn])

  return (
    <Layout>
      <Breadcrumbs title="Konto" />
      <Content />
    </Layout>
  )
}

export const Head = () => <Seo title="Konto" />

export default Account
